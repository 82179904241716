<template>
    <div>
        <template v-if="!molsloading">
    <!--        当数据加载完毕-->
            <el-breadcrumb class="app-breadcrumb" separator=">">
                    <el-breadcrumb-item :to="{ path: `/shop-${siteId}` }">
                        首页
                    </el-breadcrumb-item>
                    <el-breadcrumb-item >
                        {{goods_name}}
                    </el-breadcrumb-item>
            </el-breadcrumb>
        </template>
        <template v-else>
            <el-breadcrumb class="app-breadcrumb" separator=">">
                <el-breadcrumb-item>
                    首页
                </el-breadcrumb-item>
                <el-breadcrumb-item >
                    {{goods_name}}
                </el-breadcrumb-item>
            </el-breadcrumb>
        </template>
    </div>
</template>

<script>
    import { compile } from "path-to-regexp"

    export default {
        props: {
            hasExtItem: false,
            goods_name:{
                type:String,
                require:true
            },
            siteId:{
                type:Number,
                require:true
            },
            molsloading:{
                type:Boolean,
                required:true
            }
        },
        // data() {
        //     return {
        //         levelList: null
        //     }
        // },
        // watch: {
        //     $route() {
        //         this.getBreadcrumb()
        //     }
        // },
        // created() {
        //     this.getBreadcrumb()
        // },
        // methods: {
        //     getBreadcrumb() {
        //         // only show routes with meta.title
        //         let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
        //         const first = matched[0]
        //         if (!this.isHome(first)) {
        //             matched = [{ path: "/index", meta: { title: "首页" } }].concat(matched)
        //         }
        //
        //         this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
        //     },
        //     isHome(route) {
        //         const name = route && route.name
        //         if (!name) {
        //             return false
        //         }
        //         return name.trim().toLocaleLowerCase() === "index".toLocaleLowerCase()
        //     },
        //     pathCompile(path) {
        //         const { params } = this.$route
        //         var toPath = compile(path)
        //         return toPath(params)
        //     },
        //     handleLink(item) {
        //         const { redirect, path } = item
        //         if (redirect) {
        //             this.$router.push(redirect)
        //             return
        //         }
        //         this.$router.push(this.pathCompile(path))
        //     }
        // }
    }
</script>

<style lang="scss" scoped>
    .app-breadcrumb.el-breadcrumb {
        display: inline-block;
        font-size: 14px;
        margin: 0 auto 1px auto;
        width: 100%;

        //border-width: 1px;
        //border-style: solid;
        line-height: 30px;
        //border-color: #eae9e9;

        .el-breadcrumb__item {
            padding-left: 10px;
        }

        .no-redirect {
            color: #97a8be;
            cursor: text;
        }
    }
</style>
